import Header from '../Header/Header';
import './ChakraPage.css'
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const chakrasList = [
	{
		id: 0,
		mainName: 'Root Chakra',
		secondName: '(Muladhara)',
		questions: [
			'Where is it? ',
			'What it does?',
			'What happens when disrupted with stress? ',
		],
		answers: [
			'At the base of the spine',
			'Responsible for your sense of stability and security.',
			'You may feel emotionally insecure about your well-being, finances and basic needs.',
		],
		imageUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196702/Be_Athah_x_Chakras-07_yig8t0.png',
		thumbnailUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196702/Be_Athah_x_Chakras-07_yig8t0.png',
		imageAltText: 'Root Chakra',
		thumbnailAltText: 'Root Chakra thumbnail',
	},
	{
		id: 1,
		mainName: 'Sacral Chakra',
		secondName: '(Svadhisthana)',
		questions: [
			'Where is it? ',
			'What it does?',
			'What happens when disrupted with stress? ',
		],
		answers: [
			'Below the belly button ',
			'Responsible for sexuality, creativity, intuitiveness, self-worth, compassion, and adaptability.',
			'You may show emotional outbursts, experience a lack of creativity, and sex-obsessed thoughts.',
		],
		imageUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196704/Be_Athah_x_Chakras-06_gysdlq.png',
		thumbnailUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196704/Be_Athah_x_Chakras-06_gysdlq.png',
		imageAltText: 'Sacral Chakra',
		thumbnailAltText: 'Sacral Chakra thumbnail',
	},
	{
		id: 2,
		mainName: 'Solar Plexus Chakra',
		secondName: '(Nabhi-Manipura)',
		questions: [
			'Where is it? ',
			'What it does?',
			'What happens when disrupted with stress? ',
		],
		answers: [
			'The upper abdomen',
			'Forms the base for your self-esteem and emotions like ego, anger, and aggression.',
			'You may experience feelings of depression and low self-es teem. Physical manifestations present themselves in form of digestive problems, liver problems, or diabetes.',
		],
		imageUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196705/Be_Athah_x_Chakras-05_m09d2a.png',
		thumbnailUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196705/Be_Athah_x_Chakras-05_m09d2a.png',
		imageAltText: 'Solar Plexus',
		thumbnailAltText: 'Solar Plexus thumbnail',
	},
	{
		id: 3,
		mainName: 'Heart Chakra',
		secondName: '(Anahata)',
		questions: [
			'Where is it? ',
			'What it does?',
			'What happens when disrupted with stress? ',
		],
		answers: [
			'At the middle of the cardiovascular system',
			'Associated with the feelings of compassion, trust, passion, and love for self and others.',
			'You may experience anger, lack of trust, anxiety, jealousy, fear, and moodiness along with physical manifestations like high blood pressure, heart palpitations, and heart problems.',
		],
		imageUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196707/Be_Athah_x_Chakras-04_elxva9.png',
		thumbnailUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196707/Be_Athah_x_Chakras-04_elxva9.png',
		imageAltText: 'Heart Chakra with forest',
		thumbnailAltText: 'Heart Chakra with forest thumbnail',
	},
	{
		id: 4,
		mainName: 'Throat Chakra',
		secondName: '(Vishuddhi)',
		questions: [
			'Where is it? ',
			'What it does?',
			'What happens when disrupted with stress? ',
		],
		answers: [
			'Inside the throat.',
			'Responsible for self-expression, communication, and confidence.',
			'You may experience an irregular flow of hormones and difficulty positively communicating your inner thoughts.',
		],
		imageUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196708/Be_Athah_x_Chakras-03_luztmt.png',
		thumbnailUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196708/Be_Athah_x_Chakras-03_luztmt.png',
		imageAltText: 'nature leaves',
		thumbnailAltText: 'nature leaves thumbnail',
	},
	{
		id: 5,
		mainName: 'Third Eye Chakra',
		secondName: '(Ajna)',
		questions: [
			'Where is it? ',
			'What it does?',
			'What happens when disrupted with stress? ',
		],
		answers: [
			'Between the eyes.',
			'Responsible for intellect, intuition, wisdom, and spiritual power.',
			'You may experience overattachment to material goods along with physical manifestations like a headache, a migraine, or blurry vision.',
		],
		imageUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196710/Be_Athah_x_Chakras-02_iewr4i.png',
		thumbnailUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196710/Be_Athah_x_Chakras-02_iewr4i.png',
		imageAltText: 'Third Eye Chakra',
		thumbnailAltText: 'Third Eye Chakra thumbnail',
	},
	{
		id: 6,
		mainName: 'Crown Chakra',
		secondName: '(Sahasrara)',
		questions: [
			'Where is it? ',
			'What it does?',
			'What happens when disrupted with stress? ',
		],
		answers: [
			'At the top of the head.',
			'Responsible for your spiritual connection.',
			'You may experience depression, disconnection from the outside world, frustration, and destructive emotions.',
		],
		imageUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196711/Be_Athah_x_Chakras-01_ljywix.png',
		thumbnailUrl:
			'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1723196711/Be_Athah_x_Chakras-01_ljywix.png',
		imageAltText: 'Crown Chakra',
		thumbnailAltText: 'Crown Chakra thumbnail',
	},
];


const ChakraPage = () => {
    const [activeThumbnailId, setActiveThumbnailId] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const contentRef = useRef(null);
    const touchStartY = useRef(null);
    const lastScrollTime = useRef(0);
    const isScrolling = useRef(false);

    const { mainName, secondName, imageUrl, questions, answers } = chakrasList[activeThumbnailId];

    const bgImage = 'https://res.cloudinary.com/dzaz9bsnw/image/upload/v1724517193/Chakra_iphdnu.png';

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const changeChakra = useCallback((direction) => {
        const now = Date.now();
        if (now - lastScrollTime.current < 800) return; // Throttle to once per 800ms

        setActiveThumbnailId((prev) => {
            let next = prev + direction;
            if (next < 0) next = chakrasList.length - 1;
            if (next >= chakrasList.length) next = 0;
            return next;
        });
        lastScrollTime.current = now;
    }, []);

    const handleWheel = useCallback((e) => {
        e.preventDefault();
        if (isScrolling.current) return;
        isScrolling.current = true;
        
        const now = Date.now();
        if (now - lastScrollTime.current < 1000) {
            isScrolling.current = false;
            return;
        }
    
        const direction = e.deltaY > 0 ? 1 : -1;
        changeChakra(direction);
        
        lastScrollTime.current = now;
    
        setTimeout(() => {
            isScrolling.current = false;
        }, 300);
    }, [changeChakra]);

    const handleTouchStart = (e) => {
        touchStartY.current = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
    };

    const handleTouchEnd = useCallback((e) => {
        if (touchStartY.current === null) return;

        const touchEndY = e.changedTouches[0].clientY;
        const direction = touchStartY.current > touchEndY ? 1 : -1;
        
        if (Math.abs(touchStartY.current - touchEndY) > 50) {
            changeChakra(direction);
        }

        touchStartY.current = null;
    }, [changeChakra]);

    useEffect(() => {
        const currentContentRef = contentRef.current;
        if (currentContentRef) {
            currentContentRef.addEventListener('wheel', handleWheel, { passive: false });
        }
        return () => {
            if (currentContentRef) {
                currentContentRef.removeEventListener('wheel', handleWheel);
            }
        };
    }, [handleWheel]);

    const renderChakraThumbnails = () => {
        if (isMobile) {
            return (
                <div className='flex justify-center mt-8 space-x-4'>
                    {chakrasList.map((chakra, index) => renderChakraThumbnail(chakra, index))}
                </div>
            );
        } else {
            const topRow = chakrasList.slice(0, 4);
            const bottomRow = chakrasList.slice(4);
            return (
                <div className='flex flex-col items-center justify-center space-y-2 mr-4'>
                    <div className='flex space-x-2'>
                        {topRow.map((chakra, index) => renderChakraThumbnail(chakra, index))}
                    </div>
                    <div className='flex space-x-2'>
                        {bottomRow.map((chakra, index) => renderChakraThumbnail(chakra, index + 4))}
                    </div>
                </div>
            );
        }
    };

    const renderChakraThumbnail = (chakra, index) => (
        <motion.img
            key={index}
            src={chakra.thumbnailUrl}
            alt={chakra.thumbnailAltText}
            className={`w-9 h-9 md:w-12 md:h-12 object-cover cursor-pointer ${
                index === activeThumbnailId ? 'opacity-100 scale-110 glow' : 'opacity-100'
            }`}
            onClick={() => setActiveThumbnailId(index)}
        />
    );

    const renderChakraContent = () => (
        <AnimatePresence mode="wait">
            <motion.div
                key={activeThumbnailId}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
                className="h-full flex flex-col justify-center"
            >
                <motion.h1
                    className={`font-thin font-playfair ${
                        isMobile ? 'text-3xl' : 'text-6xl'
                    } text-[#d5c4b9] leading-tight tracking-wider mb-2 ${
                        isMobile ? 'text-left' : ''
                    }`}
                >
                    {mainName}
                </motion.h1>
                <motion.h2
                    className={`font-light ${
                        isMobile ? 'text-xl' : 'text-3xl'
                    } text-[#d5c4b9] font-playfair leading-tight tracking-wider mb-8 ${
                        isMobile ? 'text-left' : ''
                    }`}
                >
                    {secondName}
                </motion.h2>
                <div className={`space-y-6 ${isMobile ? 'text-left' : ''}`}>
                    {questions.map((question, index) => (
                        <div key={index}>
                            <p
                                className={`text-[#d5c4b9] font-medium font-urbanist ${
                                    isMobile ? 'text-lg' : 'text-lg'
                                } leading-tight tracking-widest mb-4 [word-spacing:4px]`}
                            >
                                {question}
                            </p>
                            <p
                                className={`text-[#d5c4b9] font-urbanist ${
                                    isMobile ? 'text-base' : 'text-lg'
                                } font-normal leading-7 tracking-widest [word-spacing:2px]`}
                            >
                                {answers[index]}
                            </p>
                        </div>
                    ))}
                </div>
            </motion.div>
        </AnimatePresence>
    );

    const DesktopLayout = () => (
        <div className='flex items-start justify-between w-full max-w-7xl pt-12 h-full'>
            <div 
                className='w-[50%] h-full' 
                ref={contentRef}
                onWheel={handleWheel}
            >
                {renderChakraContent()}
            </div>
            <div className='w-[40%] flex flex-col items-center'>
                <motion.img
                    key={`image-${activeThumbnailId}`}
                    src={imageUrl}
                    alt={mainName}
                    className='w-64 h-64 md:w-[350px] md:h-[350px] mb-2 z-10'
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                />
                {renderChakraThumbnails()}
            </div>
        </div>
    );

    const MobileLayout = () => (
        <div 
            className='flex flex-col items-center w-full max-w-md mx-auto min-h-screen' 
            ref={contentRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <motion.img
                key={`image-${activeThumbnailId}`}
                src={imageUrl}
                alt={mainName}
                className='w-32 h-32 mb-4'
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
            />
            {renderChakraContent()}
            <div className="mt-8">
                {renderChakraThumbnails()}
            </div>
            <p className='text-[#d5c4b9] font-urbanist text-sm mt-4 tracking-wide'>
                Swipe or tap <span className='font-bold'>Chakra</span> icon to navigate
            </p>
        </div>
    );

    return (
        <div
            className='min-h-screen bg-cover bg-center'
            style={{ backgroundImage: `url(${bgImage})` }}
        >
            <Header setIsMenuOpen={setIsMenuOpen} />
            {!isMenuOpen && (
                <div className="flex-grow flex items-start px-12 md:px-24">
                    {isMobile ? <MobileLayout /> : <DesktopLayout />}
                </div>
            )}
        </div>
    );
};

export default ChakraPage;