import React, { useState } from 'react';

const DateInput = ({ value, onChange, name, placeholder }) => {
  const [focused, setFocused] = useState(false);

  const handleChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    if (input.length > 8) input = input.slice(0, 8);
    
    let formattedDate = '';
    if (input.length > 4) {
      formattedDate += input.slice(0, 2) + '/';
      formattedDate += input.slice(2, 4) + '/';
      formattedDate += input.slice(4);
    } else if (input.length > 2) {
      formattedDate += input.slice(0, 2) + '/';
      formattedDate += input.slice(2);
    } else {
      formattedDate = input;
    }

    onChange({ target: { name, value: formattedDate } });
  };

  return (
    <input
      className='focus:outline-none bg-transparent w-full placeholder-[#8a8775] text-[#d5c4b9]'
      type='text'
      name={name}
      autoComplete='off'
      placeholder={focused ? 'DD/MM/YYYY' : placeholder}
      value={value}
      onChange={handleChange}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      maxLength={10}
    />
  );
};

export default DateInput;