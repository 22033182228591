import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Body from './components/Body/Body';
import CalmTest from './components/CalmTest/CalmTest';
import ChakraPage from './components/ChakraPage/ChakraPage';
import ContactUs from './components/ContactUs/ContactUs';

function App() {
	return (
		<div className='App select-none outline-none'>
			<Router>
				<Routes>
					<Route path='/' element={<Body />} />
					<Route path='/CalmTest' element={<CalmTest />} />
					<Route path='/Chakras' element={<ChakraPage />} />
					<Route path='/ContactUs' element={<ContactUs/>} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;