import Axios from 'axios';
import {Link} from 'react-router-dom'
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { FaYoutube } from "react-icons/fa";
import { FaXTwitter } from 'react-icons/fa6';
import { FaLinkedinIn } from 'react-icons/fa';
import { AiOutlineInstagram } from 'react-icons/ai';
import { FaFacebookF, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
	const [email, setEmail] = useState('');
	const [disabled, setDisabled] = useState(false);
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [isSubscribed, setIsSubscribed] = useState(false);

	const validateEmail = (email) => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
		setIsValidEmail(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (validateEmail(email)) {
			const Request = {
				url: process.env.REACT_APP_BACKEND + 'subscribe',
				method: 'POST',
				data: { email },
			};

			await Axios(Request);

			setEmail('');
			setDisabled(true);
			setIsSubscribed(true);
		} else {
			setIsValidEmail(false);
		}
	};

	const socialIcons = [
		{ Icon: AiOutlineInstagram, name: 'Instagram' },
		{ Icon: FaYoutube, name: 'YouTube' },
		{ Icon: FaWhatsapp, name: 'WhatsApp' },
		{ Icon: FaLinkedinIn, name: 'LinkedIn' },
		{ Icon: FaXTwitter, name: 'Twitter' },
		{ Icon: FaFacebookF, name: 'Facebook' },
	];

	return (
		<footer className='bg-[#515740] min-h-screen flex flex-col justify-between '>
			<div className='max-w-7xl w-full flex-grow flex flex-col justify-center p-12 md:p-24 lg:p-24'>
				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5 }}
					className='space-y-8 mb-12 mt-16 md:mt-[80px]'
				>
					<h2 className='font-thin font-playfair text-4xl md:text-6xl w-80 md:w-[625px] lg:w-[825px] leading-tight md:leading-none mb-10 tracking-wider text-[#d5c4b9]'>
						We are Here For You.
					</h2>
					<p className='mb-0 text-[#d5c4b9] font-urbanist text-[12px] md:text-xl w-80 md:w-[625px] lg:w-[940px] font-normal tracking-[2px] leading-6 md:leading-9'>
						We are building a new-age healthcare company for you
						that aims to significantly reduce modern
						lifestyle-related diseases through innovative and
						effective natural therapeutic solutions.
					</p>
					<p className='mb-4 text-[#d5c4b9] font-urbanist text-[12px] md:text-xl w-[300px] md:w-[625px] lg:w-[955px] font-normal tracking-[2px] leading-6 md:leading-9'>
						We aim to impact 100 million individuals, helping them
						achieve their highest potential with peace of mind.
					</p>
				</motion.div>

				<form onSubmit={handleSubmit} className='space-y-4 mb-12'>
					<div className='flex max-w-xl h-12 mt-10 md:mt-[40px]'>
						<input
							type='email'
							autoComplete='off'
							value={email}
							disabled={disabled}
							onChange={handleEmailChange}
							className={`flex-grow p-3 bg-transparent border ${
								isValidEmail
									? 'border-[#d5c4b9]'
									: 'border-red-500'
							} md:h-12 sm:h-14 border-r-0 text-[#d5c4b9] text-center font-extralight w-full rounded-l placeholder-[#8a8775] focus:outline-none`}
							placeholder='Type Your Email Address'
							aria-label='Email address'
						/>
						<motion.button
							disabled={disabled}
							whileHover={{ scale: 1.05 }}
							whileTap={{ scale: 0.95 }}
							className='bg-[#d5c4b9] text-[#515740] p-2 md:h-12 sm:h-14 md:w-48 w-28 rounded font-urbanist font-medium'
							type='submit'
						>
							{isSubscribed ? 'Submitted' : 'Sign Up'}
						</motion.button>
					</div>
					{!isValidEmail && (
						<p className='text-red-500 text-sm'>
							Please enter a valid email address.
						</p>
					)}
				</form>
				</div>
				<hr className='border-[#d5c4b9] mb-8 md:mb-12 mx-2 md:mx-8 lg:mx-20' />

				<div className='flex flex-row md:flex-row items-center justify-around gap-2 pl-1 md:pl-16 w-auto'>
					<Link to="/">
				<img
            className="w-[75.4057px] h-[40px] md:w-[140.4057px] md:h-[70px] lg:mx-0"
            src="https://res.cloudinary.com/dzaz9bsnw/image/upload/v1719729396/Be_Athah_x_Final_Logo-03_qethwg.png"
            alt="Be-Athah"
          />
		  </Link>
		  <div className='flex flex-row md:flex-row justify-around gap-2 w-full lg:-mr-3'>
          <div className="text-left lg:text-left">
            <h2 className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
              About Us
            </h2>
          </div>
          <div className="text-left lg:text-left flex flex-col gap-2">
            <h1 className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
              Science
            </h1>
            <p className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
              Blogs & Articles
            </p>
          </div>
          <div className="text-left lg:text-left flex flex-col gap-2">
            <p className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
			Privacy Policy
            </p>
            <p className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
              Terms & Condition
            </p>
			<Link to="/ContactUs">
			<p className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
              Get in touch
            </p>
			</Link>
            
          </div>
		  <div className='grid grid-cols-3 lg:justify-start gap-1 md:gap-3'>
						{socialIcons.map(({ Icon, name }) => (
							<motion.div
								key={name}
								whileHover={{ scale: 1.1 }}
								whileTap={{ scale: 0.9 }}
								className='border border-[#d5c4b9] rounded-full w-4 h-4 md:w-8 md:h-8 flex items-center justify-center p-1'
							>
								<Icon className='text-[#d5c4b9] text-lg' />
							</motion.div>
						))}
					</div>
		  </div>
					
				</div>
			
			<hr className="border-[#d5c4b9] mb-4 mt-8 md:mt-[40px] lg:mt-[40px] mx-2 md:mx-8 lg:mx-20" />
			<div className='mt-auto'>
				<div className='text-center mt-5 mb-5 md:mb-10'>
					<p className='text-[#d5c4b9] text-[6px] md:text-sm font-urbanist'>
						© Yauvanchakra Pvt. Ltd. All rights reserved.
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;