import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import { motion } from 'framer-motion';
import { IoMdMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa";
import { FaXTwitter } from 'react-icons/fa6';
import { FaLinkedinIn } from 'react-icons/fa';
import { AiOutlineInstagram } from 'react-icons/ai';
import { FaFacebookF, FaWhatsapp } from 'react-icons/fa';
import HeaderContactUs from '../../HeaderContactUs/HeaderContactUs';

const ContactUs = () => {
    const [form, setForm] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!form.name.trim()) newErrors.name = 'Name is required';
        if (!form.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(form.email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!form.message.trim()) newErrors.message = 'Message is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            
            console.log('Form submitted:', form);
        }
    };

    const socialIcons = [
		{ Icon: AiOutlineInstagram, name: 'Instagram' },
		{ Icon: FaYoutube, name: 'YouTube' },
		{ Icon: FaWhatsapp, name: 'WhatsApp' },
		{ Icon: FaLinkedinIn, name: 'LinkedIn' },
		{ Icon: FaXTwitter, name: 'Twitter' },
		{ Icon: FaFacebookF, name: 'Facebook' },
	];

    return (
        <div className="bg-[#d5c4b9] min-h-screen">
            <HeaderContactUs/>
            <div className="mx-auto flex flex-col md:flex-row justify-between items-start p-8 md:pl-[106px] md:pr-[156px] pt-[106px]">
                <div className="mb-8 md:mb-0 md:mr-12 w-full md:max-w-[384px] lg:max-w-[565px]">
                    <h2 className="font-thin text-4xl md:text-[70px] mb-6 font-playfair text-[#515740] tracking-[3px] leading-tight md:leading-tight [word-spacing:7px]">Get in touch</h2>
                    <p className="mb-2 font-medium font-urbanist text-sm md:text-base lg:text-lg text-[#515740] tracking-[3px] md:leading-5 lg:leading-10">We're Here for You</p>
                    <p className="mb-8 md:mb-4 font-medium font-urbanist text-sm md:text-base lg:text-lg text-[#515740] tracking-[3px] md:leading-5 lg:leading-10">
                        At Be Athah, your well-being is our priority. Whether you have questions about our products, need personalized advice, or simply want to share your wellness journey, we’re here to listen. Reach out to us, and let’s build your path to holistic health together.
                    </p>
                </div>
                <form onSubmit={handleSubmit} className="bg-[#515740] pt-8 pb-2 px-8 md:ml-20 rounded-lg w-full md:w-[550px] md:min-h-[380px]">
                    <h3 className="text-[25px] font-thin mb-3 text-[#d5c4b9] font-playfair tracking-widest">Send a Message</h3>
                    <div className="mb-3">
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={form.name}
                            onChange={handleChange}
                            className={`w-full pb-3 border-b ${errors.name ? 'border-red-500' : 'border-[#d5c4b9]'} bg-transparent focus:outline-none placeholder-[#404631] text-[#d5c4b9] font-urbanist text-[12px] tracking-widest`}
                        />
                        {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                    </div>
                    <div className="mb-3">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            value={form.email}
                            onChange={handleChange}
                            className={`w-full pb-3 border-b ${errors.email ? 'border-red-500' : 'border-[#d5c4b9]'} bg-transparent focus:outline-none placeholder-[#404631] text-[#d5c4b9] font-urbanist text-[12px] tracking-widest`}
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                    </div>
                    <div className="mb-3">
                        <textarea
                            name="message"
                            placeholder="Message"
                            value={form.message}
                            onChange={handleChange}
                            className={`w-full pb-3 border-b ${errors.message ? 'border-red-500' : 'border-[#d5c4b9]'} bg-transparent focus:outline-none placeholder-[#404631] text-[#d5c4b9] font-urbanist text-[12px] tracking-widest`}
                        />
                        {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
                    </div>
                    <button type="submit" className="rounded text-center w-24 md:w-24 h-8 md:h-8 p-4 mt-8 mb-8 flex items-center justify-center bg-[#d5c4b9] text-[#515740] tracking-wide">
                        Submit
                    </button>
                </form>
            </div>
            <div className='lg:pl-[106px] lg:pr-[156px]'>
            <hr className='border-[#515740]' />
            </div>
            <div className='flex flex-col md:flex-row md:justify-between items-start p-8 md:pl-[106px] md:pr-[156px] md:pt-[60px] gap-2 md:gap-5'>
                <div className="flex md:flex-col items-start gap-4">
                    <span ><IoCallSharp className='text-[#515740] text-lg md:text-3xl'/></span>
                    <span className='font-urbanist tracking-[3px] text-[#515740] font-medium text-sm md:text-[18px]'>+91 8949158054</span>
                </div>
                <div className="flex md:flex-col items-start gap-4 md:w-[270px]">
                    <span><IoLocationSharp className='text-[#515740] text-lg md:text-3xl'/></span>
                    <span className='font-urbanist tracking-[3px] text-[#515740] font-medium text-sm md:text-[18px]'>302-303, Luhadia Tower, C-Scheme, Jaipur (302001) Rajasthan</span>
                </div>
                <div className="flex md:flex-col items-start gap-4">
                    <span><IoMdMail className='text-[#515740] text-lg md:text-3xl'/></span>
                    <span className='font-urbanist tracking-[3px] text-[#515740] font-medium text-sm md:text-[18px]'>yauvanchakra@gmail.com</span>
                </div>
            </div>
            <footer className='bg-[#515740] flex flex-col justify-between '>
			
				{/* <hr className='border-[#d5c4b9] mt-6 mb-8 md:mb-12 mx-2 md:mx-8 lg:mx-20' /> */}
				<div className='flex flex-row md:flex-row items-center justify-around gap-2 pl-1 md:pl-16 w-auto mt-6'>
                    <Link to="/">
				<img
            className="w-[75.4057px] h-[40px] md:w-[140.4057px] md:h-[70px] lg:mx-0"
            src="https://res.cloudinary.com/dzaz9bsnw/image/upload/v1719729396/Be_Athah_x_Final_Logo-03_qethwg.png"
            alt="Be-Athah"
          />
          </Link>
		  <div className='flex flex-row md:flex-row justify-around gap-2 w-full lg:-mr-3'>
          <div className="text-left lg:text-left">
            <h2 className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
              About Us
            </h2>
          </div>
          <div className="text-left lg:text-left flex flex-col gap-2">
            <h1 className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
              Science
            </h1>
            <p className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
              Blogs & Articles
            </p>
          </div>
          <div className="text-left lg:text-left flex flex-col gap-2">
            <p className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
			Privacy Policy
            </p>
            <p className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
              Terms & Condition
            </p>
			<Link to="/ContactUs">
			<p className="text-[#d5c4b9] text-[8px] md:text-[16px] lg:text-[14px] sm:tracking-tight sm:leading-5 lg:tracking-widest lg:leading-10 font-urbanist font-light">
              Get in touch
            </p>
			</Link>
            
          </div>
		  <div className='grid grid-cols-3 lg:justify-start gap-1 md:gap-3'>
						{socialIcons.map(({ Icon, name }) => (
							<motion.div
								key={name}
								whileHover={{ scale: 1.1 }}
								whileTap={{ scale: 0.9 }}
								className='border border-[#d5c4b9] rounded-full w-4 h-4 md:w-8 md:h-8 flex items-center justify-center p-1'
							>
								<Icon className='text-[#d5c4b9] text-lg' />
							</motion.div>
						))}
					</div>
		  </div>
					
				</div>
			
			<hr className="border-[#d5c4b9] mt-8 md:mt-[40px] lg:mt-[40px] mx-2 md:mx-8 lg:mx-20" />
			<div>
				<div className='text-center mt-5 mb-5 md:mb-10'>
					<p className='text-[#d5c4b9] text-[6px] md:text-sm font-urbanist'>
						© Yauvanchakra Pvt. Ltd. All rights reserved.
					</p>
				</div>
			</div>
		</footer>
        </div>
    );
};

export default ContactUs;